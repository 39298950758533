APP.header = function () {
	const el = $('.header');
	const popup = $('.popup');
	const toggle = el.find('.toggle');
	const sign = el.find('.navbar__link--sign');

	function init() {
		handleScroll();
		WINDOW.on('scroll', handleScroll);
		toggle.on('click', handleToggle);
		sign.on('click', handleSign);

		$('.popup__sign').on('click', function (evt) {
			evt.preventDefault();
			const obj = $(this);
			const showPopup = obj.attr('href');
			popup.removeClass(CLASS._show);
			$(showPopup).addClass(CLASS._show);
			$('.popup__dialog').scrollTop(0);
		});

		$('.btn--close').on('click', function (evt) {
			evt.preventDefault();
			BODY.removeClass(CLASS._noscroll);
			popup.removeClass(CLASS._show);
			sign.removeClass(CLASS._show);
			$('.popup__dialog').scrollTop(0);
		});
	}

	function handleScroll() {
		let scrollTop = WINDOW.scrollTop();

		if (scrollTop > 30) {
			el.addClass(CLASS._move);
		} else {
			el.removeClass(CLASS._move);
		}
	}

	function handleToggle(evt) {
		evt.preventDefault();
		if (el.hasClass(CLASS._show)) {
			BODY.removeClass(CLASS._noscroll);
			el.removeClass(CLASS._show);
		} else {
			BODY.addClass(CLASS._noscroll);
			el.addClass(CLASS._show);
		}
	}

	function handleSign(evt) {
		evt.preventDefault();
		const obj = $(this);
		el.removeClass(CLASS._show);
		$('.popup__dialog').scrollTop(0);
		if (obj.hasClass(CLASS._show)) {
			BODY.removeClass(CLASS._noscroll);
			obj.removeClass(CLASS._show);
			popup.removeClass(CLASS._show);
		} else {
			BODY.addClass(CLASS._noscroll);
			obj.addClass(CLASS._show);
			$('.popup--signin').addClass(CLASS._show);
		}
	}

	init();
};
