const APP = {};

const DOCUMENT = $(document);
const WINDOW = $(window);
const BODY = $(document.body);
const MAIN = $('.main');

const CLASS = {
	_desktop: 'is-desktop',
	_tablet: 'is-tablet',
	_mobile: 'is-mobile',
	_portrait: 'is-portrait',
	_landscape: 'is-landscape',
	_active: 'is-active',
	_current: 'is-current',
	_error: 'is-error',
	_focus: 'is-focus',
	_noscroll: 'is-noscroll',
	_show: 'is-show',
	_move: 'is-move',
	_fixed: 'is-fixed',
};
