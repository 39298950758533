APP.contact = function () {
	const el = $('.contact');
	const textarea = el.find('textarea');
	const counter = el.find('.counter');
	const counterSub = counter.find('.counter__sub');
	const counterTotal = counter.find('.counter__total');
	let maxLength = 200;

	function init() {
		if (el.length === 0) return;

		counterTotal.text(maxLength);
		textarea.on('keyup', countChar);
	}

	function countChar(evt) {
		const obj = $(evt.target);
		let value = obj.val();
		const len = value.length;
		if (len >= maxLength) {
			value = value.substring(0, maxLength);
			obj.val(value);
			counterSub.text(maxLength);
		} else {
			counterSub.text(len);
		}
	}

	init();
};
