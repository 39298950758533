APP.preloader = function (options) {
	const settings = $.extend(
		{
			onComplete() {},
		},
		options
	);

	BODY.addClass(CLASS._noscroll);
	gsap.set(BODY, { paddingRight: APP.getScrollbarWidth() });
	BODY.imagesLoaded({ background: true }, function () {
		const preloader = $('.preloader');
		gsap.to(preloader, {
			autoAlpha: 0,
			duration: 1,
			ease: 'linear',
			onComplete() {
				preloader.remove();
				BODY.removeAttr('style');
				BODY.removeClass(CLASS._noscroll);
				settings.onComplete();
			},
		});
	});
};
