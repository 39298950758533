APP.init = function () {
	SmoothScroll({
		animationTime: 400,
		accelerationDelta: 20,
		accelerationMax: 1,
	});

	APP.collapse('.collapse', {
		isOpen: true,
	});

	$('select').select2({
		minimumResultsForSearch: -1,
		width: '100%',
		templateSelection: function (data, container) {
			if (data.id !== '') {
				$(container).addClass('select2-selection__rendered--value');
			} else {
				$(container).removeClass('select2-selection__rendered--value');
			}
			return data.text;
		},
	});

	$('.taber').each(function () {
		APP.taber(this);
	});

	APP.device();
	APP.header();
	APP.welcome();
	APP.provides();
	APP.whyVidotour();
	APP.reviews();
	APP.aboutUs();
	APP.tourist();
	APP.touristDetail();
	APP.contact();
};
