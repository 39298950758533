APP.tourist = function () {
	const el = $('.tourist');
	const touristSlider = el.find('.tourist__slider');
	const slickFor = touristSlider.find('.slick--for');
	const slickNav = touristSlider.find('.slick--nav');

	function init() {
		if (el.length === 0) return;

		slickFor.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			fade: true,
			draggable: false,
			swipe: false,
			asNavFor: '.slick--nav',
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						draggable: false,
						swipe: false,
					},
				},
			],
		});

		slickNav.slick({
			infinite: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			asNavFor: '.slick--for',
			dots: false,
			arrows: true,
			centerMode: false,
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						arrows: false,
					},
				},
			],
		});
	}

	init();
};
