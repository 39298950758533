APP.device = function () {
	function init() {
		detect();
		APP.resize(detect);
	}

	function detect() {
		BODY.removeClass(CLASS._mobile)
			.removeClass(CLASS._tablet)
			.removeClass(CLASS._desktop)
			.removeClass(CLASS._portrait)
			.removeClass(CLASS._landscape);

		if (APP.isLandscape()) {
			BODY.addClass(CLASS._landscape);
		} else {
			BODY.addClass(CLASS._portrait);
		}

		if (APP.isMobile()) {
			BODY.addClass(CLASS._mobile);
		} else if (APP.isTablet()) {
			BODY.addClass(CLASS._tablet);
		} else {
			BODY.addClass(CLASS._desktop);
		}
	}

	init();
};
