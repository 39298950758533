APP.provides = function () {
	const el = $('.provides');
	const slickProvides = el.find('.slick--provides');

	function init() {
		if (el.length === 0) return;

		slickProvides.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: false,
			dots: false,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});
	}

	init();
};
