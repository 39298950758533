APP.taber = function (element, options) {
	const el = $(element);
	const tabMenu = el.find('> .taber__menu');
	const tabBody = el.find('> .taber__body');
	const tabLink = tabMenu.find('> .taber__link');
	const tabContent = tabBody.find('> .taber__content');

	const settings = $.extend(
		{
			isHash: false,
			onComplete: function () {},
		},
		options
	);

	const init = function () {
		detect();
		tabLink.on('click', onClickTabLink);
	};

	const detect = function () {
		const hash = location.hash;

		if (hash && settings.isHash) {
			setTimeout(function () {
				$('.taber__link[href="' + hash + '"]').trigger('click');
			}, 100);
		} else {
			tabLink.eq(0).addClass(CLASS._active);
			tabContent.eq(0).addClass(CLASS._active);
		}
	};

	const detectCollapse = function () {
		const collapseShow = $('.collapse').find('.is-show');
		const collapseShowBody = collapseShow.find('.collapse__body');
		const collapseShowContent = collapseShow.find('.collapse__content');
		const collapseShowContentHeight = collapseShowContent.outerHeight();
		if (collapseShowBody.length > 0) {
			gsap.to(collapseShowBody, {
				duration: 0.3,
				height: collapseShowContentHeight,
				ease: 'power1.out',
			});
		}
	};

	const onClickTabLink = function (evt) {
		evt.preventDefault();
		const obj = $(this);
		const tabShow = obj.attr('href');
		const link = location.origin + '/' + tabShow;

		tabLink.removeClass(CLASS._active);
		obj.addClass(CLASS._active);

		tabContent.removeClass(CLASS._active);
		$(tabShow).addClass(CLASS._active);
		detectCollapse();

		if (settings.isHash) {
			location.href = link;
		}
	};

	init();
};
