APP.collapse = function (element, options) {
	const el = $(element);
	const collapse = el.find('.collapse__item');
	const collapseHead = collapse.find('.collapse__head');
	const collapseBody = collapse.find('.collapse__body');

	const settings = $.extend(
		{
			isOpen: false,
			onComplete: function () {},
		},
		options
	);

	const init = function () {
		setup();
		APP.resize(detect);
		collapseHead.on('click', handleToggle);
	};

	const setup = function () {
		if (settings.isOpen || collapse.hasClass(CLASS._show)) {
			setTimeout(function () {
				if (collapse.length > 0) {
					collapse.eq(0).find('.collapse__head').trigger('click');
					detect();
				}
			}, 100);
		}
	};

	const handleToggle = function (evt) {
		evt.preventDefault();
		const obj = $(this);
		const collapseShow = obj.parents('.collapse__item');
		const collapseShowBody = collapseShow.find('.collapse__body');
		const collapseShowContent = collapseShow.find('.collapse__content');
		const collapseShowContentHeight = collapseShowContent.outerHeight(true);

		if (collapseShow.hasClass(CLASS._show)) {
			collapseShowBody.removeClass(CLASS._show);
			collapseShow.removeClass(CLASS._show);

			gsap.to(collapseShowBody, {
				duration: 0.3,
				height: 0,
				ease: 'power1.out',
			});
		} else {
			collapseBody.removeClass(CLASS._show);
			collapse.removeClass(CLASS._show);

			gsap.to(collapseBody, {
				duration: 0.3,
				height: 0,
				ease: 'power1.out',
			});

			collapseShowBody.addClass(CLASS._show);
			collapseShow.addClass(CLASS._show);

			gsap.to(collapseShowBody, {
				duration: 0.6,
				height: collapseShowContentHeight,
				ease: 'power1.out',
			});
		}
	};

	const detect = function () {
		const collapseShow = el.find('.is-show');
		const collapseShowBody = collapseShow.find('.collapse__body');
		const collapseShowContent = collapseShow.find('.collapse__content');
		const collapseShowContentHeight = collapseShowContent.outerHeight();

		if (collapseShowBody.length > 0) {
			gsap.to(collapseShowBody, {
				duration: 0.3,
				height: collapseShowContentHeight,
				ease: 'power1.out',
			});
		}
	};

	init();
};
