APP.reviews = function () {
	const el = $('.reviews');
	const slickReviews = el.find('.slick--reviews');

	function init() {
		if (el.length === 0) return;

		slickReviews.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			dots: false,
			fade: true,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: false,
						dots: true,
					},
				},
			],
		});
	}

	init();
};
