APP.welcome = function () {
	const el = $('.welcome');
	const slickWelcome = el.find('.slick--welcome');

	function init() {
		if (el.length === 0) return;

		slickWelcome.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			dots: true,
			autoplay: true,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						dots: false,
					},
				},
			],
		});
	}

	init();
};
