APP.whyVidotour = function () {
	const el = $('.why-vidotour');
	const slickWhyVidotour = el.find('.slick--why-vidotour');

	function init() {
		if (el.length === 0) return;

		slickWhyVidotour.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			arrows: false,
			dots: false,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});
	}

	init();
};
